import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styles from '../styles/Footer.module.scss';

const Footer = ({ footerRef }) => {
  return (
    <Container fluid className={styles.footerWrapper} ref={footerRef}>
      <Row className={styles.footerTop}>
        <p>
          Not looking for digital health investing? Please visit{' '}
          <a
            href='http://www.msdlicensing.com'
            target='_blank'
            rel='noreferrer'
            aria-label='Opens in a new tab.'
          >
            MSD Licensing
          </a>{' '}
          or{' '}
          <a
            href='http://www.mrlv.com'
            target='_blank'
            rel='noreferrer'
            aria-label='Opens in a new tab.'
          >
            MRL Ventures
          </a>
        </p>
      </Row>
      <Row className={styles.footerBottom}>
        <p>
          Copyright © 2025 Merck & Co., Inc., Rahway, NJ, USA and its
          affiliates. All rights reserved.
        </p>
        <div className={styles.footerRight}>
          <a
            href='http://www.msd.com/privacy/'
            target='_blank'
            rel='noreferrer'
            aria-label='Opens in a new tab.'
          >
            Privacy
          </a>
          <a
            href='http://www.msd.com/policy/terms-of-use/home.html'
            target='_blank'
            rel='noreferrer'
            aria-label='Opens in a new tab.'
          >
            Terms of Use
          </a>
          <a
            href='http://www.msd.com'
            target='_blank'
            rel='noreferrer'
            aria-label='Opens in a new tab.'
          >
            MSD Corporate
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default Footer;
